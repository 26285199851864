import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import { Link } from "gatsby"

import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import NodeLogo from "../images/Node.js_logo.svg"
import VueLogo from "../images/Vue.js_Logo_2.svg"
import MongoLogo from "../images/mongo_logo.png"
import BistroboLayout from "../images/bistrobo-archetecture.svg"
import BistroboLayoutSingle from "../images/Tech Diagram - Single Tenant.svg"
import OAuthFlow from "../images/OAuth Flow.svg"
const BistroboBanner = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(
          relativePath: { eq: "paula-vermeulen-URjZkhqsuBk-unsplash.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <div className="screen-content"></div>
        </BackgroundImage>
      )
    }}
  />
)

export default () => {
  return (
    <Layout>
      <Header></Header>
      <div className="banner-photo">
        <BistroboBanner />
      </div>
      <div className="container full-height">
        <h1>Bistrobo</h1>
        <p>
        Bistrobo is a product I built for the restaurant industry.
              The app provides restaurants with a responsive online menu
              that customers can use to place orders. Restaurants also get an admin
              portal which they can use to manage their menu, orders, hours of operation, and more.
              Bistrobo also comes with automated text notifications and payment processing.
        </p>
        <p>
          This project began when a friend of mine needed a better online ordering app for his food truck.
          Quickly, I realized that there was potential to turn this project into a SaaS product.
        </p>
        <p>
          You can see a demo menu{" "}
          <a
            href="https://demo-menu.bistrobo.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          . To learn more or to see a demo of the admin portal you can send me a
          message through my <Link to="/#contact-form">Contact Form</Link>.
        </p>
        <h2>Tech Stack</h2>
        <p>
          Both the admin site and customer facing menu are single-page-apps
          built with Vue. These apps connect to a rest API build in express. The
          back end uses MongoDB hosted on Mongo's Atlas.
        </p>
        <div className="logoGrid">
          <div>
            <img src={NodeLogo} alt="Node JS" />
          </div>
          <div>
            <img src={VueLogo} alt="Vue JS" />
          </div>
          <div>
            <img src={MongoLogo} alt="MongoDb" />
          </div>
        </div>
        <p>
          Bistrobo also makes use of several external APIs: Payments are handled
          using Square, Twilio is used for text notification, Images are stored
          and managed in an AWS S3 bucket, and emails are sent using SendGrid.
        </p>
        <br />
        <h2>Lessons Learned</h2>
        <p>
          I ran into some challenges while building this app. Building an app that could handle payments,
          photo uploads, multiple clients, and more brought a whole host of complications. Integrating
          many moving parts into one coherent application was a tough and rewarding project.
        </p>
        <br />
        <h3>OAuth</h3>
        <p>
          Before I could use Square's payment API, I had to work
          my way through an OAuth authentication flow. It took some time
          and research to fully understand the intricacies and details of this protocol.
        </p>
        <p>
          After gaining a full understanding of OAuth flows, I was finally able to
          authorize my app with Square. As trying as the process was at
          times, I really enjoyed learning about how OAuth works, and why it
          is necessary.
        </p>
        <div className="blog-image">
          <img src={OAuthFlow} alt="OAuth Flow" />
          <span className="image-tag">OAuth Flow Diagram</span>
        </div>
        <br />
        <br />
        <h3>Planning for Scale</h3>
        <p>
          I also had to figure out how to build my app in a way that would
          scale. At first, I was deploying an entirely separate instance of the app 
          for every client. This is called 'Single Tenant Architecture',
          and knew that it would be an inefficient system to scale as the project grew.
        </p>
        <p>
          To solve this problem, I deployed a
          'multi-tenant' architecture. In this system, a single Node server was
          used to handle multiple clients, and manage multiple database
          connections. This was not as straightforward as I hoped, but through
          YouTube and Stack Overflow, anything is possible.
        </p>
        <div className="flexin">
          <div className="blog-image">
            <img
              src={BistroboLayoutSingle}
              alt="bistrobo layout single tenant"
            />
            <span className="image-tag">Single-Tenant Architecture</span>
          </div>
          <div className="blog-image">
            <img src={BistroboLayout} alt="bistrobo layout" />
            <span className="image-tag">Multi-Tenant Architecture</span>
          </div>
        </div>
        <br />
        <h3>Storing Sensitive Data</h3>
        <p>
          One unforeseen issue I had to address in this new architecture was
          where to store sensitive tokens and keys. In the single-tenant
          solution, client specific keys could be stored in environment
          variables on the server. When moving to a multi-tenant architecture,
          I knew that it would not be feasible to manage a growing list of tokens
          stored as environment variables.
        </p>
        <p>
          The solution I settled on was storing these tokens in a database.
          I took extra care to secure this database, encrypted all of the sensitive
          data that was being stored there. I then saved the encryption key as an
          environment variable on my server.
        </p>
        <br />
        <br />
        <br />
      </div>
    </Layout>
  )
}
